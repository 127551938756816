@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap);

@import "assets/scss/app.scss";

$manrope-slab: "Manrope", sans-serif;

body .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header {
  font-family: $manrope-slab;
  font-weight: normal;
  color: rgb(255, 255, 255);
}
body {
  font-family: $manrope-slab;
  font-weight: normal;
  font-size: 13px;
  background: rgba(255, 255, 255, 0) !important;
}
.page-body {
  background: rgba(255, 255, 255, 0) !important;
}
// .page-wrapper .page-body-wrapper .page-sidebar {
//   background: linear-gradient(#393f56, #272b3a);
// }
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu {
  padding: 0px;
}

.page-main-header {
  width: 100% !important;
  height: 60px !important;
  margin-left: 0px !important;
  z-index: 200 !important;
}

body .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header {
  padding: 1rem;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a {
  padding: 1rem;
  margin-bottom: 0rem;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a.active {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;

  cursor: pointer;
  border-radius: 0px;
  background: #282b3a;
  color: rgb(255, 255, 255);
  padding: 1rem;
  margin-bottom: 0rem;
  border-left-style: solid;
  border-left-color: #19a046;
  border-width: medium;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a:hover {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 1rem;
  margin-bottom: 0rem;
  cursor: pointer;
  border-radius: 0px;
  background: #282b3a;
  color: rgb(255, 255, 255);
}

.page-wrapper .page-body-wrapper .page-body {
  padding: 0px;
}

.page-main-header .main-header-right li {
  display: list-item;
  /* position: relative; */
}

///////////////////// FULL CALENDAR EVENT

// Test animation

.answerOutput {
  display: block;
  width: 100%;
  padding: 1rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  min-height: 150px;
  // margin: 20px;
  border: 1px solid #ced4da;
  border-radius: 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.answerOutputMobile {
  display: block;

  padding: 1rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  min-height: 150px;
  margin-top: 10px;
  margin-left: -35px;
  margin-right: -35px;
  border: 1px solid #ced4da;
  border-radius: 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
//////// SPECIFIC HTML FOR PREVIEW
///
.footer {
  margin-left: 0px !important;
}
.answerOutput h1 {
  font-size: 2rem;
  margin-bottom: 30px;
}
.answerOutput h2 {
  font-size: 1.5rem;
}
.answerOutput h3 {
  font-size: 1.5rem;
}
.answerOutput li {
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: 0.7px;
  cursor: pointer;
}

.answerOutput li:hover {
  background-color: #e9e9e9;
  font-weight: bold;
}
.answerOutput ul {
  padding-left: 25px;
  list-style-type: square;
  margin-bottom: 10px;
  padding-right: 0px;
}
.answerOutput p {
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: 0.7px;
  text-indent: 25px;
}

.answerOutputMobile h1 {
  font-size: 2rem;
  margin-bottom: 30px;
}
.answerOutputMobile h2 {
  font-size: 1.5rem;
}
.answerOutputMobile h3 {
  font-size: 1.5rem;
}
.answerOutputMobile li {
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: 0.7px;
  cursor: pointer;
}

.answerOutputMobile li :hover {
  background-color: #e9e9e9;
  font-weight: bold;
}
.answerOutputMobile ul {
  padding-left: 25px;
  list-style-type: square;
  margin-bottom: 10px;
  padding-right: 0px;
}
.answerOutputMobile p {
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: 0.7px;
  text-indent: 25px;
}

.verifiedUser {
  display: inline;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  background: #d2f4d3;
  color: #1a7f64;
  padding: 2px 4px 1px;
  border-radius: 3px;
  white-space: nowrap;
  margin-left: 0px;
}

.notVerifiedUser {
  display: inline;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  background: #f9cfcf;
  color: #9d2b2e;
  padding: 2px 4px 1px;
  border-radius: 3px;
  white-space: nowrap;
  margin-left: 0px;
}

.btn-light {
  background-color: #f0f0f0 !important;
}
///////// LOADER
///
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: rgb(30, 30, 30) transparent rgb(30, 30, 30) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
////////////////////
// Typewritting effect
.line-1 {
  position: relative;
  top: 50%;
  width: 24em;
  margin: 0 auto;
  border-right: 1px solid rgba(255, 255, 255, 0.75);
  font-size: 180%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
}

/* Animation */
.wrapper {
  /*This part is important for centering*/
  display: grid;
  place-items: center;
}

.typing-demo {
  width: 40ch;
  animation: typing 4s steps(34), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 1em;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

///////////// Zoom On hover icon
.iconBenefit {
  transition: transform 0.4s; /* Animation */
}

.iconBenefit:hover {
  transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

/////////////// CSS Landing page
///

.btn-action {
  cursor: pointer;
  padding: 13px 30px !important;
  position: relative;
  overflow: hidden;
  text-align: center;

  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 30px !important;
  background-color: #5842bc !important;
  color: #fff !important;
  border: none;
  font-weight: 500 !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 5px;
  font-size: 15px;
}
.btn-action-light {
  display: inline-block;
  font-weight: 500 !important;

  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 13px 30px !important;

  border-radius: 30px !important;
  border-color: #0002 !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 5px;
  font-size: 15px;
}
.btn-action-mobile {
  cursor: pointer;
  padding: 8px 20px;
  position: relative;
  overflow: hidden;
  text-align: center;

  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 5px !important;
  background-color: #5842bc !important;
  color: #fff !important;
  border: none;
  font-weight: 500 !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 5px;
  font-size: 13px;
}
.btn-action-light-mobile {
  cursor: pointer;
  display: inline-block;
  font-weight: 500 !important;

  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 8px 20px;

  border-radius: 5px !important;
  border-color: #0002 !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 5px;
  font-size: 13px;
}

.btn-action:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 7px !important;
  background-color: #5842bce8 !important;
}
.btn-action-light:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 7px !important;
  background-color: rgba(88, 66, 188, 0.04) !important;
  color: #000 !important;
}
.btn-action-mobile:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 7px !important;
  background-color: #5842bce8 !important;
}
.btn-action-light-mobile:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 7px !important;
  background-color: rgba(88, 66, 188, 0.04) !important;
  color: #000 !important;
}

button:focus {
  outline: 0px dotted !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.historicList {
  cursor: pointer;
  border-bottom-style: solid;
  border-bottom-color: #ececf1;
  border-bottom-width: 1.5px;
}
.historicList:hover {
  border-left-style: solid;
  border-left-color: #5842bc;
  border-left-width: 3px;
}

.historicList-active {
  background-color: #a6a6a640;
  border-left-style: solid;
  border-left-color: #5842bc;
  border-left-width: 3px;
}

// Sub menu mobile
.page-main-header-sub-mobile {
  width: 100% !important;
  height: fit-content !important;
  margin-left: 0px !important;
  z-index: 200 !important;
  background-color: #fff;

  position: fixed;
  top: 60px;
  z-index: 2;
  box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);

  display: flex;
  align-items: center;

  transition: 0.3s;
}

// Language Select

.form-select {
  font-size: 13px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

// Background

.wave {
  width: 70%;
  position: fixed;
  right: -40%;
  bottom: 0;
  z-index: -10;
  opacity: 0.2;
}

.card {
  border-radius: 5px !important;
  border: solid 1px #e5e5e5;
  box-shadow: 6px 6px 13px 0 rgba(0, 0, 0, 0.05) !important;
  // box-shadow: 0px 4px 6px 0 rgba(34, 60, 80, 0.16) !important;
  // background-color: #f9f9f9 !important;
  border: 1px solid rgb(230, 230, 230) !important;
}

.card-product {
  // background-color: #f9f9f9;
  // border: #eee;
  text-align: center;
  padding: 5px 8%;
  // background-color: #f1f2fa;
  border-radius: 5px !important;
  overflow: hidden;
  margin: 5px 0px !important;
  padding: 5px 0px !important;
  display: block;
  color: #000;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}

.card-selected {
  // box-shadow: inset 1px 2px 5px #777;
  box-shadow: none !important;
  border: solid 2px;
}

.card-product:hover {
  box-shadow: none !important;
}
.container {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

// .iconProduct {
//   background-size: cover;
//   width: 6rem;
//   height: 6rem;
//   border-radius: 50%;
// }

.containerIconProductGreen {
  color: #1f5e25;
  background-color: rgb(201, 230, 202);
  width: 4rem;
  height: 4rem;
  font-size: 0.7rem;
  border-radius: 50%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  animation: glowingEffect 5s linear infinite;
}

.containerIconProductBlue {
  color: #1678ba;
  background-color: #b5e5fb;
  width: 4rem;
  height: 4rem;
  font-size: 0.7rem;
  border-radius: 50%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  animation: glowingEffectBlue 5s linear infinite;
}

.containerIconProductYellow {
  color: #ff8f00;
  background-color: #ffecb3;
  width: 4rem;
  height: 4rem;
  font-size: 0.7rem;
  border-radius: 50%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  animation: glowingEffectYellow 5s linear infinite;
}
.iconProduct {
  font-size: 3em;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(+50%);
  position: absolute;
}

@keyframes glowingEffectGreen {
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: #1f5e2537 0px 0px 6px 10px;
  }
  100% {
    box-shadow: none;
  }
}

@keyframes glowingEffectYellow {
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: #ff910032 0px 0px 6px 10px;
  }
  100% {
    box-shadow: none;
  }
}

@keyframes glowingEffectBlue {
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: #1678ba31 0px 0px 6px 10px;
  }
  100% {
    box-shadow: none;
  }
}

// Alert :

// Carousel :

.coverflow__container__1P-xE {
  // overflow: unset !important;
  background: rgba(0, 0, 0, 0) !important;
}
